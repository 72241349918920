<template>
  <div style="height:100%" v-loading="isLoading">
    <iframe class="iframe-box" src="https://hnt.bagps.cn:448/#/passport/loginroute?username=gljj" width="100%" height="100%"></iframe>
  </div>
</template>

<script>
import { Authentication } from "@/api/external"
export default {
  name: 'DispatchingManagementXY',
  data() {
    return {
      iframeUrl: '',
      isLoading: false,
    }
  },
  created() {
    // this.init();
  },
  methods: {
    async init() {
      try {
        this.isLoading = true;
        let data = await Authentication({
          UserName: '广东睿隆混凝土有限公司',
          PassWord: 'Rl112233',
          Secret: "d4WnQvJ3xAqVFFn8S7Vy6wDJZJqJCjO0PfJE2V6xPbU6rkfWQs2wMLcjqBKTBMQ=",
        });
        this.isLoading = false;
        if (data && data.Status) {
          this.iframeUrl = `http://hnt.jetone.cn:8102/Web/#/login?userName=${'广东睿隆混凝土有限公司'}&token=${data.Message}`
        }else {
          this.init();
        }
      } catch (error) {
        this.isLoading = false;
      }
    }
  }
}
</script>

<style>
.iframe-box {
  border: none !important;
}
</style>